import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  CheckboxFilter,
  ExpiredPopup,
  IconAndTitle,
  Pagination,
  Tabs,
} from "src/components";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  app,
  assets,
  pageLinks,
} from "src/configs";
import {
  ActionType,
  useAppContext,
  useLoadingContext,
  useLoginContext,
  useSearchFlightContext,
} from "src/context";
import { DetailedFlightInfo, FilterDetail } from "src/sections";
import { CheckoutService, FlightService } from "src/services";
import { orderBy } from "src/types";
import { serviceType } from "src/types/checkout";
import {
  FlightByIDResponse,
  FlightFilterOptionID,
  FlightFilterParams,
  FlightSearchResponse,
  flightFilterOptionID,
  flightSearchSortBy,
} from "src/types/flight";
import { formatDateForBE } from "src/utils";
import classes from "./SearchFlight.module.scss";
import { FilterSection, LoadingBar, SideFilter } from "./components";

const UnmemoSearchFlight: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useSearchFlightContext();
  const { loading, isLoading, unlockScreen } = useLoadingContext();
  const { user } = useLoginContext();
  const { showError, showSuccess } = useAppContext();

  const [flights, setFlights] = useState<FlightSearchResponse["results"]>(
    undefined
  );

  const [flightByID, setFlightByID] = useState<
    FlightByIDResponse["itineraryDetails"]
  >(undefined);

  const [filter, setFilter] = useState<FlightFilterParams>({
    transactionId: "",
    cabinClass: state.cabin,
    policyId: state.isAdmin ? undefined : user.policyId,
    page: {
      number: 1,
      resultsPerPage: 1,
    },
    sorting: flightSearchSortBy.best,
    sortOrder: orderBy.ascending,
    travelPolicies: false,
    stops: [],
    cabins: [],
    hasBaggage: false,
    airlines: [],
    time0: [],
    time1: [],
  });

  const [doFilter, setDoFilter] = useState<boolean>(false);

  const getFlights = async () => {
    loading.start();

    if (state.flightFrom && state.flightTo) {
      const destinations = state.returnDate
        ? [
            {
              departureDate: formatDateForBE(state.departureDate),
              departureTime: "00:00",
              fromPlace: {
                code: state.flightFrom.code,
              },
              toPlace: {
                code: state.flightTo.code,
              },
            },
            {
              departureDate: formatDateForBE(state.returnDate),
              departureTime: "00:00",
              fromPlace: {
                code: state.flightTo.code,
              },
              toPlace: {
                code: state.flightFrom.code,
              },
            },
          ]
        : [
            {
              departureDate: formatDateForBE(state.departureDate),
              departureTime: "00:00",
              fromPlace: {
                code: state.flightFrom.code,
              },
              toPlace: {
                code: state.flightTo.code,
              },
            },
          ];

      const response = await FlightService.search({
        destinations,
        directOnly: state.directOnly,
        travelClasses: [state.cabin],
        travelersIds: state.isAdmin
          ? state.users.map(({ id }) => id)
          : [user.id],
        policyId: state.isAdmin ? undefined : user.policyId,
      });

      if (response.success && response.results) {
        if (response.results.itineraryGroups.length !== 0) {
          setFlights(response.results);
        } else {
          showError("No Flights Found.");
        }
      } else showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    if (user.id !== 0) getFlights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const id = flights ? flights.transactionId : "";
    const expirationTime = flights ? flights.expirationTime : null;
    dispatch({
      type: ActionType.updateTransactionId,
      payload: id,
    });

    if (expirationTime) {
      dispatch({
        type: ActionType.updateExpirationTime,
        payload: expirationTime,
      });
    }

    setFilter({ ...filter, transactionId: id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights]);

  const filterFlights = async () => {
    loading.start();

    if (state.transactionId !== "") {
      const response = await FlightService.filter(filter);

      if (response.success && response.results) setFlights(response.results);
      else showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  const getFlightByID = async (id: string) => {
    loading.start();

    if (state.transactionId !== "" && flights) {
      const response = await FlightService.getByID({
        transactionId: state.transactionId,
        id,
      });

      if (response.success && response.itineraryDetails)
        setFlightByID(response.itineraryDetails);
      else showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    if (doFilter) {
      filterFlights();
      setDoFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFilter]);

  const NoFlight: React.FC = () => {
    if (isLoading) return <></>;

    return <IconAndTitle title="No Flight Found" image={assets.emptyTable} />;
  };

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page: { number: page } });
    setDoFilter(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSortChange = (sorting: FlightFilterParams["sorting"]) => {
    setFilter({ ...filter, sorting });
    setDoFilter(true);
  };

  const Result: React.FC = () => {
    if (flights) {
      const { itineraryGroups, departureDate, returnDate } = flights;

      const pageNumbers = Array.from(
        { length: flights.page.totalPages },
        (_, index) => index + 1
      );

      return (
        <>
          <Tabs
            tabList={[
              {
                title: "Recommended",
                onClick: () => {
                  handleSortChange(flightSearchSortBy.best);
                },
                isActive: flights.sortedBy === flightSearchSortBy.best,
              },
              {
                title: "Cheapest",
                onClick: () => {
                  handleSortChange(flightSearchSortBy.cheapest);
                },
                isActive: flights.sortedBy === flightSearchSortBy.cheapest,
              },
              {
                title: "Fastest",
                onClick: () => {
                  handleSortChange(flightSearchSortBy.fastest);
                },
                isActive: flights.sortedBy === flightSearchSortBy.fastest,
              },
            ]}
          />

          {itineraryGroups.map((itineraryGroup, i) => (
            <DetailedFlightInfo
              {...itineraryGroup}
              departureDate={departureDate}
              returnDate={returnDate}
              key={i}
              onSelect={getFlightByID}
            />
          ))}

          {pageNumbers.length > 1 && (
            <Pagination>
              {pageNumbers.map((pageNumber, i) => (
                <button
                  key={i}
                  disabled={flights.page.pageNumber === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
            </Pagination>
          )}
        </>
      );
    }

    return <NoFlight />;
  };

  const handleFilter = (id: FlightFilterOptionID, value: any) => {
    if (id === flightFilterOptionID.airline) {
      setFilter({ ...filter, airlines: value });
    }

    if (id === flightFilterOptionID.baggage) {
      setFilter({ ...filter, hasBaggage: value });
    }

    if (id === flightFilterOptionID.stops) {
      setFilter({ ...filter, stops: value });
    }

    if (id === flightFilterOptionID.time) {
      setFilter({ ...filter });
    }

    if (id === flightFilterOptionID.time0) {
      setFilter({ ...filter, time0: value });
    }

    if (id === flightFilterOptionID.time1) {
      setFilter({ ...filter, time1: value });
    }

    if (id === flightFilterOptionID.travelPolicy) {
      setFilter({ ...filter, travelPolicies: value });
    }

    setDoFilter(true);
  };

  const selectFlight = async () => {
    loading.start();

    if (state.transactionId !== "" && flightByID) {
      const response = await CheckoutService.select({
        serviceType: serviceType.flight,
        transactionId: state.transactionId,
        serviceItemId: flightByID.identifier,
        cabinClass: "Y",
      });

      if (response.success)
        showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      else showError(response.message ? response.message : ERROR_MESSAGE);
    } else showError(ERROR_MESSAGE);

    unlockScreen();
    loading.stop();

    navigate(pageLinks.checkout.confirmTravelers.link);
  };

  return (
    <Layout title={`${pageLinks.searchFlight.name} - ${app.name}`}>
      <CenteredGrid>
        <FilterSection getFlights={getFlights} />
        {isLoading && <LoadingBar />}{" "}
        <div className={classes.main}>
          <div className={classes.sidebar}>
            <SideFilter
              totalRecords={flights?.page?.totalResults}
              options={
                flights
                  ? flights.filterOptions.map(
                      ({ filterId, name, items, filters }) => {
                        if (
                          filterId === flightFilterOptionID.stops ||
                          filterId === flightFilterOptionID.airline
                        ) {
                          return {
                            name: name,
                            data: (
                              <CheckboxFilter
                                options={items.map((item) => ({
                                  name: item.name,
                                  value: item.itemId,
                                }))}
                                onChange={(selected: string[]) =>
                                  handleFilter(filterId, selected)
                                }
                              />
                            ),
                          };
                        }

                        if (
                          filterId === flightFilterOptionID.baggage ||
                          filterId === flightFilterOptionID.travelPolicy
                        )
                          return { data: <></> };

                        if (filterId === flightFilterOptionID.time) {
                          return {
                            data: (
                              <>
                                {/* RangeInput component can be added here */}
                              </>
                            ),
                          };
                        }

                        return { name: name, data: <></> };
                      }
                    )
                  : []
              }
            />
          </div>
          <div className={classes.results}>
            {flights && flights.itineraryGroups.length > 0 ? (
              <Result />
            ) : (
              <NoFlight />
            )}
          </div>
        </div>
      </CenteredGrid>

      {flights && (
        <FilterDetail
          onClose={() => {
            setFlightByID(undefined);
          }}
          flight={flightByID}
          selectFlight={selectFlight}
          legDescriptions={flights.groupDescription[0].legDescriptions}
        />
      )}

      {!flights && (
        <CenteredGrid>
          <div className={classes.main}>
            <div className={classes.sidebar}>
              <Skeleton height={50} count={8} width={250} />
            </div>
            <div className={classes.results}>
              <div className={classes.skeleton}>
                <Skeleton height={150} width={926} />
                <Skeleton count={3} width={926} />
              </div>
              <div className={classes.skeleton}>
                <Skeleton height={150} width={926} />
                <Skeleton count={3} width={926} />
              </div>
              <div className={classes.skeleton}>
                <Skeleton height={150} width={926} />
                <Skeleton count={3} width={926} />
              </div>
              <div className={classes.skeleton}>
                <Skeleton height={150} width={926} />
                <Skeleton count={3} width={926} />
              </div>
            </div>
          </div>
        </CenteredGrid>
      )}

      <ExpiredPopup hasRefresh />
    </Layout>
  );
};

export const SearchFlight = React.memo(UnmemoSearchFlight);
