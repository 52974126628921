import React, { useState } from "react";
import {
  Button,
  Card,
  Icon,
  Image,
  LabelValue,
  Popup,
  PrimaryLink,
  Tag,
  Text,
  TravelerList,
  TravelerName,
} from "src/components";
import { flightImagesURL, icons } from "src/configs";
import { FlightRoute } from "src/sections";
import { componentSizes, componentVariants } from "src/types";
import { BookingStatus, bookingStatus } from "src/types/checkout";
import { ItineraryGroup } from "src/types/flight";
import { TripInfo } from "src/types/traveler";
import { formatDateInMonth, formatPrice } from "src/utils";
import classes from "./DetailedFlightInfo.module.scss";

type Props = ItineraryGroup & {
  departureDate: string;
  returnDate?: string;
  onSelect?: (id: string) => void;
  isShort?: boolean;
  travelerDetails?: TripInfo["travelerDetails"];
  onBookmark?: any;
  onDelete?: any;
  tripCustomNumber?: string;
  createDate?: string;
  showPrice?: boolean;
  hideMainDate?: boolean;
  status?: BookingStatus;
  isFullBoxClickAble?: boolean;
  cta?: {
    name: string;
    action: any;
  };
  onOpenDetail?: any;
};

const UnmemoDetailedFlightInfo: React.FC<Props> = ({
  itinerary,
  pricingInformation,
  identifier,
  policy,
  nonRefundable,
  departureDate,
  returnDate,
  // baggageInfo,
  onSelect,
  isShort = false,
  travelerDetails,
  onBookmark,
  onDelete,
  tripCustomNumber,
  createDate,
  showPrice = false,
  hideMainDate = false,
  status,
  isFullBoxClickAble = false,
  cta,
  onOpenDetail,
}) => {
  const { currency = "", passengerTotalAmount } = pricingInformation;

  const { originDestinationOption } = itinerary.originDestinationOptions;

  const duration =
    itinerary.originDestinationOptions.originDestinationOption[0]
      .totalFlightTimeText;

  const hasPolicy = policy !== null && policy.inPolicy === false;

  const [isActivePopup, setIsActivePopup] = useState<boolean>(false);

  if (isShort)
    return (
      <div className={classes.details}>
        {originDestinationOption.map(
          ({ flightSegments, layoverSummary }, i) => {
            const { origin, carrier } = flightSegments[0];

            const { destination } = flightSegments[flightSegments.length - 1];

            return (
              <div key={i}>
                {!hideMainDate && (
                  <>
                    <Text tag="h3">
                      {formatDateInMonth(
                        i === 0
                          ? departureDate
                          : returnDate
                          ? returnDate
                          : "10/12/2000"
                      )}
                    </Text>
                    <Text tag="h4">Flight To {destination.city}</Text>
                    <br />
                  </>
                )}
                <Card className={classes.row}>
                {i === 0 && showPrice && passengerTotalAmount && (
                    <div className={classes.flightRow}>
                      <div className={classes.labelWithDetail}>
                        <div>
                          <LabelValue
                            label="Amount"
                            value={`${formatPrice(
                              currency,
                              passengerTotalAmount
                            )}`}
                          />
                        </div>
                        {status && (
                          <div>
                            <LabelValue
                              label="Status"
                              node={
                                <>
                                  <Tag
                                    variant={
                                      status === bookingStatus.approved
                                        ? "green"
                                        : "grey"
                                    }
                                    size={componentSizes.small}
                                  >
                                    {status}
                                  </Tag>
                                </>
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={classes.flightRow}>
                    <div className={classes.logo}>
                      <Image
                        src={`${flightImagesURL}${carrier.operatingAirline.iataCode}.png`}
                        alt=""
                      />
                    </div>
                    <div className={classes.flight}>
                      <FlightRoute
                        from={{
                          locationCode: origin.locationCode,
                          date:
                            i === 0
                              ? departureDate
                              : returnDate
                              ? returnDate
                              : "",
                          time: origin.time,
                        }}
                        to={{
                          locationCode: destination.locationCode,
                          date:
                            i === 0
                              ? departureDate
                              : returnDate
                              ? returnDate
                              : "",
                          time: destination.time,
                          dateAdjustment: destination.dateAdjustment,
                        }}
                        duration={duration}
                        layoverSummary={layoverSummary}
                      />
                    </div>
                    <div className={classes.otherDetails}>
                      {/* <div className={classes.luggage}>
                        {baggageInfo[0].weight && baggageInfo[0].unit ? (
                          <>
                            <Text tag="h6">
                              {`${baggageInfo[0].weight}${baggageInfo[0].unit}`}{" "}
                              Included
                            </Text>
                            <Icon src={icons.baggage.green} />
                          </>
                        ) : (
                          <Text tag="h6">No Baggage Info Available</Text>
                        )}
                      </div> */}
                      {destination?.fareInfo?.segment?.seatsAvailable && (
                        <Tag variant="green" size={componentSizes.small}>
                          {destination?.fareInfo?.segment?.seatsAvailable} Seat
                          Left
                        </Tag>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            );
          }
        )}
      </div>
    );

  return (
    <>
      <div
        className={`${classes.wrapper} ${
          isFullBoxClickAble ? classes.clickable : ""
        }`}
        id={`flight-${identifier}`}
        onClick={
          onSelect && isFullBoxClickAble
            ? () => {
                onSelect(identifier);
              }
            : undefined
        }
      >
        <Card> 
          <div className={classes.header}>
            <div className={classes.left}>
              {nonRefundable && (
                <Tag variant="red" size={componentSizes.medium}>
                  Non Refundable
                </Tag>
              )}
              {tripCustomNumber && (
                <Tag variant="green" size={componentSizes.small}>
                  Booking ID: {tripCustomNumber}
                </Tag>
              )}
              {hasPolicy && (
                <div
                  className={classes.outOfPolicy}
                  onClick={() => setIsActivePopup(true)}
                >
                  <Icon src={icons.info.red} />
                  <Text className={classes.policyText}>Out of Policy</Text>
                </div>
              )}
            </div>
            <div className={classes.right}>
              {createDate && (
                <Text size={componentSizes.small} className={classes.text}>
                  Created on {formatDateInMonth(createDate)}
                  {new Date(createDate).getFullYear() !==
                  new Date().getFullYear()
                    ? `, ${new Date(createDate).getFullYear()}`
                    : ""}
                </Text>
              )}
              {onOpenDetail && (
                <>
                  <PrimaryLink onClick={onOpenDetail}>
                    Flight Details
                  </PrimaryLink>
                </>
              )}
              {onBookmark && (
                <button>
                  <Icon src={icons.bookmark.color} />
                </button>
              )}
              {onDelete && (
                <button onClick={() => onDelete()}>
                  <Icon src={icons.delete.color} />
                </button>
              )}
            </div>
          </div>

          <div className={classes.details}>
            {originDestinationOption.map(
              ({ flightSegments, layoverSummary }, i) => {
                const { origin, carrier } = flightSegments[0];

                const { destination } = flightSegments[
                  flightSegments.length - 1
                ];

                return (
                  <div className={classes.flightRow} key={i}>
                    <div className={classes.logo}>
                      <Image
                        src={`${flightImagesURL}${carrier.operatingAirline.iataCode}.png`}
                        alt=""
                      />
                    </div>
                    <div className={classes.flight}>
                      <FlightRoute
                        from={{
                          date:
                            i === 0
                              ? departureDate
                              : returnDate
                              ? returnDate
                              : "",
                          time: origin.time,
                          locationCode: origin.locationCode,
                        }}
                        to={{
                          date:
                            i === 0
                              ? departureDate
                              : returnDate
                              ? returnDate
                              : "",
                          time: destination.time,
                          locationCode: destination.locationCode,
                          dateAdjustment: destination.dateAdjustment,
                        }}
                        duration={duration}
                        layoverSummary={layoverSummary}
                      />
                    </div>
                    <div className={classes.otherDetails}>
                      {/* <div className={classes.luggage}>
                    {baggageInfo[0].weight && baggageInfo[0].unit ? (
                      <>
                        <Text tag="h6">
                          {`${baggageInfo[0].weight}${baggageInfo[0].unit}`}{" "}
                          Included
                        </Text>
                        <Icon src={icons.baggage.green} />
                      </>
                    ) : (
                      <Text tag="h6">No Baggage Info Available</Text>
                    )}
                  </div> */}
                      {destination?.fareInfo?.segment?.seatsAvailable && (
                        <Tag variant="green" size={componentSizes.small}>
                          {destination?.fareInfo?.segment?.seatsAvailable} Seat
                          Left
                        </Tag>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>

          <div className={classes.footer}>
            <div className={classes.traveler}>
              {travelerDetails && (
                <>
                  <TravelerList travelers={travelerDetails} />
                  <TravelerName travelers={travelerDetails} />
                </>
              )}
            </div>
            <div className={classes.price}>
              {passengerTotalAmount && (
                <div className={classes.text}>
                  <Text tag="h6">From</Text>
                  <Text tag="h1">
                    {formatPrice(currency, passengerTotalAmount)}
                  </Text>
                </div>
              )}
              {cta && (
                <Button
                  variant={componentVariants.secondary}
                  onClick={() => cta.action}
                >
                  {cta.name}
                </Button>
              )}
              {onSelect && !isFullBoxClickAble && (
                <Button onClick={() => onSelect(identifier)}>
                  Show Details
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
      {hasPolicy && isActivePopup && (
        <Popup
          title={"Policy Details"}
          onClose={{
            ctaName: "Close",
            ctaAction: () => setIsActivePopup(false),
          }}
        >
          {policy.violations.map(({ text, desc }, i) => (
            <div className={classes.policyRow} key={i}>
              <Text tag="h5">{text}:</Text>
              <Text tag="h6">{desc}</Text>
            </div>
          ))}
        </Popup>
      )}
    </>
  );
};

export const DetailedFlightInfo = React.memo(UnmemoDetailedFlightInfo);
