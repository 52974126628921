import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import {
  Button,
  CenteredGrid,
  IconAndTitle,
  Pagination,
  Tabs,
  Tag,
} from "src/components";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  app,
  assets,
  pageLinks,
} from "src/configs";

import { useNavigate } from "react-router-dom";
import { API_BASE_URL, endpoints } from "src/configs";
import { useAppContext, useLoadingContext } from "src/context";
import { FilterDetail, FlightInfo } from "src/sections";
import { TripService } from "src/services";
import { componentSizes } from "src/types";
import { BookingStatus, bookingStatus } from "src/types/checkout";
import { token } from "src/utils";

import {
  ChangeRequestParams,
  TripByIDResponse,
  TripInfo,
  TripUpdateParams,
  TripsParams,
  TripsResponse,
  tripStatus,
} from "src/types/traveler";
import classes from "./Trips.module.scss";
import { RejectionPopup, RequestChangesPopup } from "./components";

const NoTrips: React.FC = () => {
  return <IconAndTitle title="No Trips Found" image={assets.emptyTable} />;
};

const UnmemoTrips: React.FC = () => {
  const navigate = useNavigate();
  const { loading, unlockScreen } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [filter, setFilter] = useState<Omit<TripsParams, "status">>({
    pageIndex: 0,
    pageSize: 0,
  });

  const [loadingTabIndex, setLoadingTabIndex] = useState<number | null>(null);

  const handleTabClick = (index: number) => {
    setLoadingTabIndex(index); // Show skeleton loader
    setIsLoading(true); // Ensure skeleton is visible
    // Fetch data for the selected tab
    // switch (index) {
    //   case 0:
    //     getTrips(bookingStatus.upcomingTab);
    //     break;
    //   case 1:
    //     getTrips(bookingStatus.past);
    //     break;
    //   case 2:
    //     getTrips(bookingStatus.onHold);
    //     break;
    //   case 3:
    //     getTrips(bookingStatus.pendingApproval);
    //     break;
    //   case 4:
    //     getTrips(bookingStatus.cancelled);
    //     break;
    //   case 5:
    //     getTrips(bookingStatus.other);
    //     // break;
    //     // default:
    //     break;
    // }

    // Determine the new status based on the tab index
    const newStatus = [
      bookingStatus.upcomingTab,
      bookingStatus.past,
      bookingStatus.onHold,
      bookingStatus.pendingApproval,
      bookingStatus.cancelled,
      bookingStatus.other,
    ][index];

    // Update status and fetch trips for the selected tab
    setStatus(newStatus);
  };

  const LoadingIcon = () => <span>Loading...</span>;

  const [isLoading, setIsLoading] = useState(true);

  // const handleTabClick = (index: number) => {
  //   setIsLoading(true); // Show skeleton loader
  // }
  // You can add additional logic here if needed
  const [doFilter, setDoFilter] = useState<boolean>(false);

  const [isRejectionPopupActive, setIsRejectionPopupActive] = useState<boolean>(
    false
  );

  const [isChangesPopupActive, setIsChangesPopupActive] = useState<
    number | undefined
  >(undefined);

  const [flightByID, setFlightByID] = useState<
    TripByIDResponse["orderSummary"]
  >(undefined);

  const [trips, setTrips] = useState<TripsResponse["result"]>();
  const [totalTrips, setTotalTrips] = useState<{
    upcomingTab: number;
    past: number;
    pendingApproval: number;
    other: number;
    onHold: number;
    cancelled: number;
  }>({
    upcomingTab: 0,
    past: 0,
    pendingApproval: 0,
    other: 0,
    onHold: 0,
    cancelled: 0,
  });
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  const [status, setStatus] = useState<BookingStatus>(
    bookingStatus.upcomingTab
  );

  const getTrips = async (statusForList: BookingStatus) => {
    loading.start();

    const response = await TripService.get({
      status: statusForList,
      ...filter,
    });
    if (response.success && response.result) setTrips(response.result);
    else {
      setTrips(undefined);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    setStatus(statusForList);

    const totalTrips = await TripService.getCount();
    if (totalTrips.success && totalTrips.totals) {
      const total = totalTrips.totals;
      setTotalTrips({
        upcomingTab:
          total.find((trip) => trip.status === bookingStatus.upcomingTab)
            ?.total || 0,
        past:
          total.find((trip) => trip.status === bookingStatus.past)?.total || 0,

        pendingApproval:
          total.find((trip) => trip.status === bookingStatus.pendingApproval)
            ?.total || 0,

        cancelled:
          total.find((trip) => trip.status === bookingStatus.cancelled)
            ?.total || 0,
        other:
          total.find((trip) => trip.status === bookingStatus.other)?.total || 0,
        onHold:
          total.find((trip) => trip.status === bookingStatus.onHold)?.total ||
          0,
      });
    } else {
      setTotalTrips({
        upcomingTab: 0,
        past: 0,
        pendingApproval: 0,
        cancelled: 0,
        other: 0,
        onHold: 0,
      });
      showError(totalTrips.message ? totalTrips.message : ERROR_MESSAGE);
    }

    loading.stop();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    getTrips(status);
    return () => clearTimeout(timer); // Cleanup the timer on component unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  const onDelete = async (id: TripInfo["id"]) => {
    loading.start();

    const response = await TripService.delete(id);
    if (response.success)
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
    else showError(response.message ? response.message : ERROR_MESSAGE);

    await getTrips(status);

    loading.stop();
  };

  useEffect(() => {
    if (doFilter) {
      getTrips(status);
      setDoFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFilter, status]);

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, pageIndex: page - 1 });
    setDoFilter(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getFlightByID = async (id: TripInfo["id"]) => {
    loading.start();

    const response = await TripService.getByID(id);

    if (response.success && response.orderSummary) {
      setFlightByID(response.orderSummary);
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  const Result: React.FC = () => {
    if (trips && trips.tripInfo.length > 0) {
      const pageNumbers = Array.from(
        { length: trips.totalPages },
        (_, index) => index + 1
      );
      return (
        <>
          {trips.tripInfo.map(
            (
              {
                itineraryGroup,
                travelerDetails,
                id,
                departureDate,
                returnDate,
                tripCustomNumber,
                createDate,
                changeRequestInfo,
                approvalInfo,
                status,
              },
              i
            ) => (
              <div key={i} className={classes.list}>
                <div className={classes.listHeader}></div>
                <FlightInfo
                  {...itineraryGroup}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  key={i}
                  travelerDetails={travelerDetails}
                  onDelete={
                    status === bookingStatus.OnHold
                      ? () => onDelete(id)
                      : undefined
                  }
                  tripCustomNumber={tripCustomNumber}
                  status={status}
                  createDate={createDate}
                  onOpenDetail={() => getFlightByID(id)}
                  onSelect={(id_dummy: string) => getFlightByID(id)}
                  isFullBoxClickAble
                  cta={
                    status === bookingStatus.upcoming
                      ? { name: "Request Changes", action: () => {} }
                      : undefined
                  }
                />
                {/* <Line /> */}
                <div className={classes.bottom}>
                  {status === bookingStatus.waiting &&
                    approvalInfo.canTakeAction && (
                      <Button
                        size={componentSizes.small}
                        onClick={() => {
                          unlockScreen();
                          navigate(
                            `${pageLinks.approval.checkout.link}?id=${id}`
                          );
                        }}
                      >
                        View Details
                      </Button>
                    )}

                  {status === bookingStatus.OnHold && (
                    <Button
                      size={componentSizes.small}
                      onClick={async () => {
                        setLoadingStates((prevState) => ({
                          ...prevState,
                          [id]: true,
                        }));
                        try {
                          const response = await fetch(
                            `${API_BASE_URL}${endpoints.trips.proceed}/${id}`,
                            {
                              method: "PUT",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token.get()}`,
                                Accept: "*/*",
                              },
                            }
                          );

                          if (!response.ok) {
                            if (response.status === 404) {
                              console.error(
                                "Endpoint not found. Please check the URL."
                              );
                            } else {
                              console.error(
                                `Failed to proceed with the trip: ${response.statusText}`
                              );
                            }
                            setLoadingStates((prevState) => ({
                              ...prevState,
                              [id]: false,
                            }));
                            return;
                          }

                          const data = await response.json();

                          if (data.success) {
                            window.location.reload();
                          } else {
                            console.error(
                              "Failed to proceed with the trip:",
                              data.message
                            );
                            setLoadingStates((prevState) => ({
                              ...prevState,
                              [id]: false,
                            }));
                          }
                        } catch (error) {
                          console.error(
                            "Error occurred while proceeding with the trip:",
                            error
                          );
                          setLoadingStates((prevState) => ({
                            ...prevState,
                            [id]: false,
                          }));
                        }
                      }}
                    >
                      {loadingStates[id] ? <LoadingIcon /> : "Proceed"}
                    </Button>
                  )}

                  {(status === bookingStatus.issued ||
                    status === bookingStatus.upcoming) && (
                    <>
                      {changeRequestInfo.requestSubmitted ? (
                        <Tag size={componentSizes.large} variant={"red"}>
                          Request Submitted
                        </Tag>
                      ) : (
                        <Button
                          size={componentSizes.small}
                          onClick={() => {
                            setIsChangesPopupActive(id);
                          }}
                        >
                          Request Changes
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            )
          )}

          {pageNumbers.length > 1 && (
            <Pagination>
              {pageNumbers.map((pageNumber, i) => (
                <button
                  key={i}
                  disabled={trips.pageNumber === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
            </Pagination>
          )}
        </>
      );
    }
    return <NoTrips />;
  };

  const onChangeStatus = async (params: TripUpdateParams) => {
    loading.start();

    const response = await TripService.update(params);

    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      window.location.reload();
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  const onChangeTrip = async (params: ChangeRequestParams) => {
    loading.start();

    const response = await TripService.change(params);

    if (response.success) {
      showSuccess(response.message ? response.message : SUCCESS_MESSAGE);
      window.location.reload();
    } else showError(response.message ? response.message : ERROR_MESSAGE);

    loading.stop();
  };

  return (
    <Layout title={`${pageLinks.trips.name} - ${app.name}`}>
      <CenteredGrid>
        <div className={classes.row}></div>
        <div className="row">
          <div className="col-lg-12">
            <Tabs
              hasFullWidth={false}
              tabList={[
                {
                  title: `Upcoming (${totalTrips.upcomingTab})`,
                  onClick: () => handleTabClick(0),

                  isActive: status === bookingStatus.upcomingTab,
                },
                {
                  title: `Past (${totalTrips.past})`,
                  onClick: () => handleTabClick(1),
                  isActive: status === bookingStatus.past,
                },
                {
                  title: `On Hold (${totalTrips.onHold})`,
                  onClick: () => handleTabClick(2),
                  isActive: status === bookingStatus.onHold,
                },
                {
                  title: `Pending Approval (${totalTrips.pendingApproval})`,
                  onClick: () => handleTabClick(3),
                  isActive: status === bookingStatus.pendingApproval,
                },
                {
                  title: `Cancelled (${totalTrips.cancelled})`,
                  onClick: () => handleTabClick(4),
                  isActive: status === bookingStatus.cancelled,
                },
                {
                  title: `Other (${totalTrips.other})`,
                  onClick: () => handleTabClick(5),
                  isActive: status === bookingStatus.other,
                },
              ]}
              onTabClick={handleTabClick} // Pass handleTabClick
            />
            {isLoading ? (
              <Skeleton count={5} height={30} width={`100%`} />
            ) : (
              <Result />
            )}
          </div>
        </div>
      </CenteredGrid>

      {isChangesPopupActive && (
        <RequestChangesPopup
          onHide={() => {
            setIsChangesPopupActive(undefined);
          }}
          onChange={onChangeTrip}
          id={isChangesPopupActive}
        />
      )}

      {flightByID && (
        <>
          {isRejectionPopupActive && (
            <RejectionPopup
              onHide={() => {
                setIsRejectionPopupActive(false);
              }}
              onReject={(reason: string) => {
                onChangeStatus({
                  id: flightByID.id,
                  status: tripStatus.reject,
                  rejectionReason: reason,
                });
              }}
            />
          )}

          {!isRejectionPopupActive && (
            <FilterDetail
              onClose={() => {
                setFlightByID(undefined);
                unlockScreen();
              }}
              flight={flightByID.itineraryDetails}
              legDescriptions={flightByID.groupDescription[0].legDescriptions}
              onApprove={
                flightByID.approvalInfo.canTakeAction
                  ? () => {
                      unlockScreen();
                      navigate(
                        `${pageLinks.approval.checkout.link}?id=${flightByID.id}`
                      );
                    }
                  : undefined
              }
              onReject={
                flightByID.approvalInfo.canTakeAction
                  ? () => {
                      setIsRejectionPopupActive(true);
                    }
                  : undefined
              }
            />
          )}
        </>
      )}
    </Layout>
  );
};

export const Trips = React.memo(UnmemoTrips);
