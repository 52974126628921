import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import { CenteredGrid } from "src/components";
import { app } from "src/configs";
import { ActionType, useSearchFlightContext } from "src/context";
import { FlightSearch } from "src/sections";
import { cabin } from "src/types/travelPolicy";
import classes from "./Home.module.scss";

const UnmemoHome: React.FC = () => {
  const { dispatch } = useSearchFlightContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a loading delay for demonstration purposes
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    dispatch({
      type: ActionType.updateExpirationTime,
      payload: new Date(new Date().getTime() + 30 * 60 * 1000),
    });

    dispatch({
      type: ActionType.updateFlightFrom,
      payload: {
        code: "",
        cityName: "",
      },
    });

    dispatch({
      type: ActionType.updateFlightTo,
      payload: {
        code: "",
        cityName: "",
      },
    });

    dispatch({
      type: ActionType.updateCabin,
      payload: cabin.economy,
    });

    return () => clearTimeout(timer); // Cleanup the timer on component unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {isLoading ? (
        <CenteredGrid>
          <div className={classes.main}>
            <div className={classes.top}>
              <Skeleton height={72} />
            </div>
            <div className={classes.results}>
              <div className={classes.skeleton}>
                <Skeleton height={100} />
              </div>
              <div className={classes.skeleton}>
                <Skeleton height={350} />
              </div>
            </div>
          </div>
        </CenteredGrid> */}
      {/* // ) : ( */}
        <Layout title={`${app.name} - ${app.tagline}`} isHome={true}>
          <FlightSearch />
        </Layout>
      {/* // )} */}
    </>
  );
};

export const Home = React.memo(UnmemoHome);
