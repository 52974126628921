import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "src/Layout";
import {
  CenteredGrid,
  DataTable,
  DataTableColumn,
  SectionTitle,
} from "src/components";
import { ERROR_MESSAGE, app, pageLinks } from "src/configs";
import { useAppContext, useLoadingContext, useLoginContext } from "src/context";
import { TravelerService } from "src/services";
import { TableConfigs } from "src/types";
import {
  Status,
  Traveler,
  TravelerDetails,
  TravelerSearchParams,
  TravelersListResponse,
  status as statusValues,
} from "src/types/traveler";
import { EditForm, InviteForm, UserDetails } from "./components";
import { DEFAULT_TABLE_CONFIGS } from "./configs";
import { TravelerTable, transformTravelerList } from "./utils";

const columns: DataTableColumn<TravelerTable>[] = [
  { dataKey: "id", header: "ID" },
  { dataKey: "name", header: "Name" },
  { dataKey: "email", header: "Email" },
  { dataKey: "role", header: "Role Name" },
  { dataKey: "status", header: "Status" },
];

const UnmemoUserManagement: React.FC = () => {
  const { user } = useLoginContext();
  const { loading, unlockScreen } = useLoadingContext();
  const { showError, showSuccess } = useAppContext();

  const [openSidebar, setOpenSidebar] = useState(false); // For sidebar
  const [openPopup, setOpenPopup] = useState(false); // For popup

  const [list, setList] = useState<TravelerTable[]>([]); // For table list
  const [selectedRows, setSelectedRows] = useState<TravelerTable[]>([]); // For selected rows

  const [search, setSearch] = useState(false); // To search
  const [searchParams, setSearchParams] = useState<TravelerSearchParams>({
    pageIndex: 0,
  }); // Search payload

  const [travelDetails, setTravelDetails] =
    useState<TravelerDetails["travelerInfo"]>(undefined); // Save information of one user

  const [tableConfigs, setTableConfigs] = useState<TableConfigs>(
    DEFAULT_TABLE_CONFIGS
  ); // Table configs

  const [editID, setEditID] = useState<Traveler["id"] | undefined>(undefined); // To update user info

  // -------- Table configurations start --------
  const updateTable = (response: TravelersListResponse) => {
    if (response.success && response.results) {
      setList(transformTravelerList(response.results.travelers));

      setTableConfigs({
        pageNumber: response.results.pageNumber,
        pageSize: response.results.pageSize,
        totalItems: response.results.totalItems,
        totalPages: response.results.totalPages,
        firstItem: response.results.firstItem,
        lastItem: response.results.lastItem,
      });
    } else {
      setList([]);

      setTableConfigs(DEFAULT_TABLE_CONFIGS);
    }
  };
  // -------- Table configurations end --------

  // -------- Table sorting start --------

  const onSort = (
    sortBy: TravelerSearchParams["sortBy"],
    orderBy: TravelerSearchParams["orderBy"]
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      sortBy,
      orderBy,
    }));

    setSearch(true);
  };

  // -------- Table sorting end --------

  // -------- Table Listing start --------
  const getList = async () => {
    loading.start();

    window.scrollTo({ top: 0, behavior: "smooth" });

    const response = await TravelerService.getList();
    updateTable(response);

    loading.stop();
  };

  useEffect(() => {
    if (user.id !== 0) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSearch = async () => {
    loading.start();

    window.scrollTo({ top: 0, behavior: "smooth" });

    const response = await TravelerService.search(searchParams);
    updateTable(response);

    setSearch(false);

    loading.stop();
  };

  useEffect(() => {
    if (search) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onRowsSelected = (selectedRows: TravelerTable[]) => {
    setSelectedRows(selectedRows);
  };

  const onChangePagination = (number: number) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      pageIndex: number - 1,
    }));
    setSearch(true);
  };

  const onSearchChange = (value: string) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      name: value,
    }));
  };

  const onSearchUsers = () => {
    setSearch(true);
  };
  // -------- Table Listing ends --------

  // -------- Popup Handling start --------
  const showInviteUser = () => setOpenPopup(true);
  const hideInviteUser = () => setOpenPopup(false);
  // -------- Popup Handling ends --------

  // -------- Delete Handling starts --------
  const handleDelete = async (ids: Traveler["id"][]) => {
    loading.start();

    const response = await TravelerService.delete({ ids });

    if (response.success) showSuccess(response.message);
    else showError(response.message);

    getList();
    loading.stop();
  };

  const onDelete = async (user: Traveler) => {
    handleDelete([user.id]);
  };

  const onDeleteBulk = async () => {
    const ids: number[] = selectedRows.map((list) => list.key.id);
    handleDelete(ids);
  };
  // -------- Delete Handling ends --------

  // -------- Update Handling starts --------
  const onUpdate = (user: Traveler) => {
    setEditID(user.id);
  };

  const afterUpdate = () => {
    setEditID(undefined);
    getList();
  };

  const cancelUpdate = () => {
    setEditID(undefined);
  };
  // -------- Update Handling end --------

  // -------- Status Handling starts --------
  const handleStatusChange = async (ids: Traveler["id"][], status: Status) => {
    loading.start();

    const response =
      status === statusValues.active
        ? await TravelerService.deactivate({ ids })
        : await TravelerService.activate({ ids });
    if (response.success) showSuccess(response.message);
    else showError(response.message);

    getList();
    loading.stop();
  };

  const onStatusChange = async (user: Traveler & { key?: Traveler }) => {
    handleStatusChange([user.id], user.key?.status || user.status);
  };

  const onDeactivateBulk = async () => {
    const ids: number[] = selectedRows.map((list) => list.key.id);
    handleStatusChange(ids, statusValues.active);
  };

  const onActivateBulk = async () => {
    const ids: number[] = selectedRows.map((list) => list.key.id);
    handleStatusChange(ids, statusValues.inActive);
  };
  // -------- Status Handling ends --------

  // -------- Sidebar Handling starts --------
  const onCloseSideBar = () => {
    unlockScreen();
    setOpenSidebar(false);
    setTravelDetails(undefined);
  };
  // -------- Sidebar Handling ends --------

  // -------- Row click action starts --------
  const onClickRow = async (user: Traveler) => {
    loading.start();

    const response = await TravelerService.getById(user.id);
    if (response.success && response.travelerInfo) {
      setTravelDetails(response.travelerInfo);
      setOpenSidebar(true);
    } else {
      setTravelDetails(undefined);
      setOpenSidebar(false);
      showError(response.message ? response.message : ERROR_MESSAGE);
    }

    loading.stop();
  };
  // -------- Row click action ends --------

  return (
    <Layout title={`${pageLinks.userManagement.name} - ${app.name}`}>
      <CenteredGrid>
        <SectionTitle
          title={pageLinks.userManagement.name}
          tableData={{
            first: tableConfigs.firstItem,
            last: tableConfigs.lastItem,
            total: tableConfigs.totalItems,
          }}
        />

        {list.length === 0 ? (
          <Skeleton height={40} count={10} />
        ) : (
          <DataTable<TravelerTable>
            data={list}
            columns={columns}
            onSelectedRowsChange={onRowsSelected}
            onDelete={onDelete}
            onDeleteBulk={onDeleteBulk}
            onUpdate={onUpdate}
            onStatusChange={onStatusChange}
            onDeActiveBulk={onDeactivateBulk}
            onActivateBulk={onActivateBulk}
            onClick={onClickRow}
            mainCTA={{ name: "Invite User", action: showInviteUser }}
            totalPages={tableConfigs.totalPages}
            pageNumber={tableConfigs.pageNumber}
            onChangePagination={onChangePagination}
            onSearchChange={onSearchChange}
            onSearch={onSearchUsers}
            hasSort={{
              onSort,
              sortBy: searchParams.sortBy,
              orderBy: searchParams.orderBy,
            }}
          />
        )}

        {/* Popup starts */}
        {openPopup && user && (
          <InviteForm
            onHide={hideInviteUser}
            travelPolicies={user.travelPolicies}
          />
        )}
        {/* Popup ends */}

        {/* Side drawer starts */}
        {/* {travelDetails && ( */}
        <UserDetails
        isActive={true}
        onClose={onCloseSideBar}
        accountInformation={travelDetails?.accountInformation}
        travelPolicyOverview={travelDetails?.travelPolicyOverview}
        tripsOverview={travelDetails?.tripsOverview}
        />
        {/* )} */}
        {/* Side drawer ends */}
      </CenteredGrid>
    </Layout>
  );
};

export const UserManagement = React.memo(UnmemoUserManagement);
